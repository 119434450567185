import { css } from "@emotion/react";

export const noStyle = css`
  appearance: none;
  border: none;
  background-color: transparent;
  text-align: left;
  font-size: inherit;
  cursor: pointer;

  &:active {
    color: inherit;
  }
`;

export const disabled = css`
  cursor: not-allowed;
`;
